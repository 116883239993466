import React, { useCallback, useState, useEffect } from "react";
import "../../ui/components/login/login.css";
import { BsFillPersonFill } from "react-icons/bs";
import { AuthLogin, GetUsersInfo, PostNewPassword, ResetPassword, SendCode } from "../../data/services";
import secureLocalStorage from "react-secure-storage";
 

import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import SenhaComponent from "./Senha";
import NewSenhaComponent from "./NewSenha";

const Login = () => {
  const [typeClient, setTypeClient] = useState<number>(0);
  const [username, setUsername] = useState<string>("");
  const [user, setUser] = useState<any>("");
  const [password, setPassword] = useState<string>("");
  const [res, setRes] = useState<any>();
  const [error, setError] = useState<any>(false);
  const [firstAccess, setFirstAccess] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [consultEmail, setConsultEmail] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState(false)
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirmPassword: "",
    openModalNewPassword: false,
  });

  const [modalAlterarSenha, setModalAlterarSenha] = useState(false)

  const [errorCode, setErrorCode] = useState({
    error: false,
    text: "",
  });

  const [code, setCode] = useState("");

  const [senhaValida, setSenhaValida] = useState(false);

  function handleRecaptcha(value: string | null): void {
    setCaptcha(value !== null)
  }

  const Login = useCallback(
    (username: string, password: string, type: string, event: any) => {
      event.preventDefault();
      AuthLogin({
        username: username,
        password: password,
        profile: type,
        setVal: setRes,
      });

      if (firstAccess) {
        setTypeClient(0);
      }

      GetUsersInfo(setUser, username)
      secureLocalStorage.setItem("profile", type);
    },
    [firstAccess]
  );

  useEffect(() => {
    if (res?.response?.data?.idUsuario && res?.response?.data?.mensagem === 'Acesso negado, precisa mudar a senha') {
      setFirstAccess(true);
    }
    if (res?.response?.data?.mensagem) {
      if (
        res?.response?.data?.mensagem === "Email não existe no cadastro Notifier" ||
        res?.response?.data?.mensagem === "Erro ao logar usuário interno" ||
        res?.response?.data?.mensagem === "Matrícula não existe no cadastro Notifier" ||
        res?.response?.status === 401
      ) {
        if (res?.response?.data?.mensagem === "Email não existe no cadastro Notifier" || res?.response?.data?.mensagem === "Matrícula não existe no cadastro Notifier") {
          setError({
            error: true,
            message: res?.response?.data?.mensagem,
          });
        } else {

          setError({
            error: true,
            message: res?.response?.data?.mensagem,
          });
        }
      }
      if (res?.status === 502) {
        secureLocalStorage.clear()
        setError({
          error: true,
          message:
            "Ocorreu um problema ao realizar o login. Atualize a página e tente novamente!",
        });
      }
    }
    if (!!user) {
      const userInfo = user[0]

      const login = userInfo?.login
      const nomeUsuario = userInfo?.nomeUsuario
      const profile = userInfo?.perfilAcesso
      const idFabrica = userInfo?.idFabrica
      const idUsuario = userInfo?.idUsuario
      const tipo = userInfo?.tipo
      const email = userInfo?.email

      secureLocalStorage.setItem('login', login)
      secureLocalStorage.setItem('nomeUsuario', nomeUsuario)
      secureLocalStorage.setItem('profile', profile)
      secureLocalStorage.setItem('idFabrica', idFabrica)
      secureLocalStorage.setItem('idUsuario', idUsuario)
      secureLocalStorage.setItem('tipo', tipo)
      secureLocalStorage.setItem('email', email)

      // secureLocalStorage.setItem('user', JSON.stringify(user));
    }
    if (res?.data?.access_token) {
      secureLocalStorage.setItem("access_token", res?.data?.access_token);
      secureLocalStorage.setItem("username", username);

      window.location.href = "/home";
    }
    if (res?.mensagem === "Matrícula não existe no cadastro Notifier") {
      setError({
        error: true,
        message: res?.data?.message,
      });
    }
  }, [res]);

  const ConfirmCode = useCallback((value: string, e: any) => {
    e.preventDefault();

    if (!value) {
      return setErrorCode({
        error: true,
        text: "Por favor, insira o código",
      });
    }

    setFirstAccess(false);

    setTypeClient(0);

    setModalAlterarSenha(true)

  }, []);

  const soliciteCode = async () => {
    try {

      const body = {
        email: username
      }

      if (body.email.length == 0) return

      await SendCode(body, setError)

      setTypeClient(0);
      setConsultEmail(false);
      setResetPassword(true)

    } catch (error) {
      console.log(`Houve um erro inesperado: ${error}`)
      return setErrorCode({
        error: true,
        text: `${error}`,
      });
    }
  }

  const handleResetPassword = async () => {
    try {
      if(senhaValida){
        const body = {
          "email": username,
          "verificationCode": code,
          "novaSenha": password
        }
        await ResetPassword(body, setError)
  
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      else {
        return alert("Se atente aos requisitos da senha")
      }

    } catch (error) {
      return setErrorCode({
        error: true,
        text: `${error}`,
      });
    }
  }

const ConfirmNewPassword = useCallback(
  async (e: any) => {
    e.preventDefault();
 
    if (newPassword.password === newPassword.confirmPassword) {
      try {
        const obj = {
          "idUsuario": res?.response?.data?.idUsuario,
          "confirmationCode": code,
          "senhaAntiga": password,
          "novaSenha": newPassword.password,
        };
 
        const response = await PostNewPassword(obj);
 
        if (response?.status !== 200) {
          return setErrorCode({
            error: true,
            text: response?.data?.mensagem || "Ocorreu um erro. Tente novamente.",
          });
        } else {
          setErrorCode({
            error: false,
            text: "Nova senha confirmada com sucesso!",
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        }
      } catch (error: any) {
        console.error("Error in ConfirmNewPassword:", error);
        if (error.response && error.response.data && error.response.data.mensagem)
        setErrorCode({
          error: true,
          text: error.response.data.mensagem,
        });
      }
    } else {
      setErrorCode({
        error: true,
        text: "As senhas não são iguais. Por favor, tente novamente.",
      });
    }
  },
  [code, newPassword.confirmPassword, newPassword.password, res?.data?.idUsuario]
);

  function handleInputChange(event: any) {
    setError(false);
    const value = event.target.value;
    const onlyNums = /^[0-9\b]+$/; // expressão regular que permite apenas números
    if (value === "" || onlyNums.test(value)) {
      setUsername(value);
    }
  }

  function handleKeyPress(event: any) {
    const keyCode = event.keyCode || event.which; // pega o código da tecla pressionada
    const keyValue = String.fromCharCode(keyCode); // pega o valor da tecla pressionada
    const onlyNums = /^[0-9]*$/; // expressão regular que permite apenas números
    if (!onlyNums.test(keyValue)) {
      event.preventDefault(); // impede a entrada do caractere não numérico
    }
  }


  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <section
          style={{
            textAlign: "center",
            width: "300px",
            boxShadow: "0 0 10px 0 rgb(0 0 0 / 10%)",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <div className="content-logo">
            <img
              src="/assets/img/logo_notifier.png"
              className="logo-notify"
              alt="logo"
            />
          </div>

          {typeClient === 1 ? (
            <form
              onSubmit={(e) => {
                Login(username, password, "usuarioColaborador", e);
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <input
                required
                type="text"
                pattern="[0-9]*"
                className="input-login"
                maxLength={8}
                minLength={8}
                placeholder="Colaborador"
                onKeyPress={handleKeyPress}
                onChange={handleInputChange}
              />
              <input
                required
                type="password"
                className="input-login"
                placeholder="senha"
                onChange={(e) => {
                  setError(false);
                  setPassword(e.target.value);
                }}
              />
              {/* <input type="submit" value="submit" /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <input
                  type="button"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  onClick={() => {
                    setError(false);
                    setTypeClient(0);
                  }}
                  value="Voltar"
                />
                <input
                  type="submit"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  value="Entrar"
                />
              </div>
              {error.error && (
                <div className="alert alert-danger" role="alert">
                  {error.message}
                </div>
              )}
            </form>
          ) : typeClient === 2 && !firstAccess ? (
            <form
              onSubmit={(e) => {
                Login(username, password, "usuarioFornecedor", e);
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <input
                required
                type="email"
                className="input-login"
                placeholder="Fornecedor"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <input
                required
                type="password"
                className="input-login"
                placeholder="senha"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <span
                className="resetPassword"
                onClick={() => {
                  setTypeClient(0);
                  setConsultEmail(true);
                }
                }>
                Esqueci minha senha
              </span>
              <div style={{ display: "flex", marginBottom: 10 }}>
                <input
                  type="button"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  onClick={() => {
                    setError(false);
                    setTypeClient(0);
                  }}
                  value="Voltar"
                />
                <input
                  type="submit"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  value="Entrar"
                />
              </div>
              {error.error && (
                <div className="alert alert-danger" role="alert">
                  {error.message}
                </div>
              )}
              <GoogleReCaptcha onVerify={handleRecaptcha} />
            </form>
          ) : firstAccess ? (
            <form onSubmit={(e) => ConfirmCode(code, e)} style={{ overflow: 'hidden' }}>
              <h1 style={{ color: "grey", fontSize: 18 }}>
                Olá, este é seu primeiro acesso. 
                No momento da criação do seu usuário enviamos um código para seu email. Por favor, confirme o código.
              </h1>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="code-input"
              />
              <div style={{ display: "flex", margin: "15px 0" }}>
                <input
                  type="button"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  onClick={() => {
                    setError(false);
                    setFirstAccess(false);
                    setTypeClient(0);
                  }}
                  value="Voltar"
                />
                <input
                  type="submit"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  value="Confirmar"
                  onClick={() => {
                    setError(false);
                  }}
                />
              </div>
              {errorCode.error && (
                <div className="alert alert-danger" role="alert">
                  {errorCode.text}
                </div>
              )}
              {!errorCode.error && errorCode.text && (
                <div className="alert alert-success" role="alert">
                  {errorCode.text}
                </div>
              )}
            </form>
          ) : consultEmail ? (
            // TODO: ajeitar o campo de email, ta mt espaçado pra direita
            <form style={{ overflow: 'hidden' }}>
              <h1 style={{ color: "grey", fontSize: 18 }}>
                Antes de resetar sua senha, insira seu email:
              </h1>
              <input
                required
                type="email"
                className="input-login"
                placeholder="Seu email"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <div style={{ display: "flex", margin: "15px 0" }}>
                <input
                  type="button"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  onClick={() => {
                    setError(false);
                    setConsultEmail(false);
                    setTypeClient(2);
                  }}
                  value="Voltar"
                />
                <input
                  type="button"
                  className="btn-log"
                  style={{ width: 200, marginRight: 5, color: "white" }}
                  value="Validar email"
                  onClick={async () => {
                    await soliciteCode();
                  }}
                />
              </div>
              {error.error && (
                <div className="alert alert-danger" role="alert">
                  {error.message}
                </div>
              )}
            </form>
          )
            : resetPassword ? 
            (
              <form style={{ overflow: 'hidden' }}>
                <h1 style={{ color: "grey", fontSize: 18 }}>
                  Insira o código que recebeu por email:
                </h1>
                <input
                  type="text"
                  value={code}
                  placeholder="código"
                  onChange={(e) => setCode(e.target.value)}
                  className="code-input"
                />
                <SenhaComponent validaSenha={setSenhaValida} senhaAtual={setPassword}/> 
                {error.error && (
                  <div className="alert alert-danger" role="alert">
                    {error.message}
                  </div>
                )}

                <div style={{ display: "flex", margin: "15px 0" }}>
                  <input
                    type="button"
                    className="btn-log"
                    style={{ width: 200, marginRight: 5, color: "white" }}
                    onClick={() => {
                      setError(false);
                      setResetPassword(false);
                      setConsultEmail(true);
                    }}
                    value="Voltar"
                  />
                  <input
                    type="button"
                    className="btn-log"
                    value="Salvar nova senha"
                    disabled={!senhaValida}
                    style={{
                      width: 200, marginRight: 5, color: "white",
                      opacity: !senhaValida ? 0.4 : 1, cursor: !senhaValida ? "not-allowed" : 'pointer', 
                    }}
                    onClick={async () => {
                      await handleResetPassword()
                      setError(false);
                    }}
                  />
                  {/* TODO: Criar função de limite para pedir um novo código */}
                  {/* <span
                  className="resetPassword"
                  onClick={() => {
                    setTypeClient(0);
                    setConsultEmail(true);
                  }
                  }>
                  Reenviar código 
                </span> 
                // fazer tratativa de desabilitar depois de 1min
                */}
                </div>
                {errorCode.error && (
                  <div className="alert alert-danger" role="alert">
                    {errorCode.text}
                  </div>
                )}
                {!errorCode.error && errorCode.text && (
                  <div className="alert alert-success" role="alert">
                    {errorCode.text}
                  </div>
                )}
              </form>
            )
              : modalAlterarSenha ? (
                <form
                  onSubmit={(e) => ConfirmNewPassword(e)}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h1 style={{ color: "grey", fontSize: 18, lineHeight: 1.5 }}>
                    Digite uma nova senha com esses requisitos:
                  </h1>

                <NewSenhaComponent validaSenha={setSenhaValida} senhaAtual={setNewPassword}/> 

                  <div style={{ display: "flex", marginBottom: 10 }}>
                    <input
                      type="button"
                      className="btn-log"
                      style={{ width: 200, marginRight: 5, color: "white" }}
                      onClick={() => {
                        setError(false);
                        setTypeClient(0);
                        setModalAlterarSenha(false)
                      }}
                      value="Voltar"
                    />
                    <input
                      type="submit"
                      className="btn-log"
                      value="Entrar"

                      disabled={!senhaValida}
                      style={{
                        width: 200, marginRight: 5, color: "white",
                        opacity: !senhaValida ? 0.4 : 1, cursor: !senhaValida ? "not-allowed" : 'pointer', 
                      }}
                    />
                  </div>
                  {errorCode.error && (
                    <div className="alert alert-danger" role="alert">
                      {errorCode.text}
                    </div>
                  )}
                  {!errorCode.error && errorCode.text && (
                    <div className="alert alert-success" role="alert">
                      {errorCode.text}
                    </div>
                  )}
                </form>
              ) : (
                <div style={{ display: "flex", marginTop: 10 }}>
                  <button className="button-login" onClick={() => setTypeClient(1)}>
                    <p style={{ margin: 0, display: "flex", alignItems: "center" }}>
                      <BsFillPersonFill />
                    </p>
                    Colaborador
                  </button>

                  <button
                    className="button-login"
                    onClick={() => setTypeClient(2)}
                    style={{ marginLeft: "5px" }}
                  >
                    @ Fornecedor
                  </button>
                </div>
              )}
          <div className="content-logo-petropolis">
            <img
              className="logo-petropolis"
              src="/assets/img/logo-petropolis.png"
              alt="logo"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Login;
