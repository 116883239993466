import React, { useState } from "react";
import HeaderUser from "../header/header";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { GoGraph } from "react-icons/go";
import { RiAdminLine } from "react-icons/ri";
import "./homeHeader.css";
import { useAuth } from "../../../context/auth";

interface PropType {
  children: React.ReactNode;
}

const HomeHeader = ({ children }: PropType) => {
  const { user } = useAuth();

  const forn = user.profile !== "usuarioFornecedor" ? false : true;

  const privilegeAcess = user.profile === "superAdmin" ? true : false;

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  return (
    <>
      <HeaderUser />

      <div className="wrapper">
        <nav id="sidebar">
          <div className="sidebar-header">
            <img
              style={{ width: "250px" }}
              src="/assets/img/logo_notifier.png"
              alt="logo"
            />
          </div>

          <ul className="list-unstyled components">
            <li>
              <a
                href="/home"
                style={{
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BsFillHouseDoorFill size={20} style={{ marginRight: 20 }} />
                Principal
              </a>
            </li>
            <li>
              <a
                href="/Nce"
                style={{ fontSize: 16, display: "flex", alignItems: "center" }}
              >
                <BiEdit size={20} style={{ marginRight: 20 }} />
                Não Conformidades
              </a>
            </li>
            <li>
              <a
                style={{ display: "flex", alignItems: "center" }}
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
                href="/estratificacoes"
              >
                <GoGraph size={20} style={{ marginRight: 20 }} />
                Estratificação de Defeitos
              </a>
            </li>
            <li>
              <a
                href="/analitico"
                style={{ display: "flex", alignItems: "center" }}
              >
                <GoGraph size={20} style={{ marginRight: 20 }} />
                PQMF - Analítico
              </a>
            </li>
            {!forn && (
              <li>
                <a
                  href="/comparativo"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <GoGraph size={20} style={{ marginRight: 20 }} />
                  PQMF - Comparativo
                </a>
              </li>
            )}
            <li>
              <a
                href="/mensal"
                style={{ display: "flex", alignItems: "center" }}
              >
                <GoGraph size={20} style={{ marginRight: 20 }} />
                PQMF - Mensal
              </a>
            </li>
            {/* {!forn &&
              <li>
                <a href="/ranking" style={{ display: "flex", alignItems: "center" }}>
                  <GoGraph size={20} style={{ marginRight: 20 }} />
                  PQMF - Ranking
                </a>
              </li>} */}
            {!forn && (
              <li
                style={{
                  backgroundColor: openDrawer ? "grey" : "",
                  transition: ".2s",
                }}
              >
                <a
                  onClick={() => setOpenDrawer(!openDrawer)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <RiAdminLine size={20} style={{ marginRight: 20 }} />
                  Área Administrativa
                </a>
                {openDrawer && (
                  <div style={{ marginLeft: 65 }}>
                    <a
                      href="/admin-users"
                      style={{ cursor: "pointer", background: "grey" }}
                    >
                      Usuários
                    </a>
                    <a
                      href="/admin-product"
                      style={{ cursor: "pointer", background: "grey" }}
                    >
                      Produtos
                    </a>
                    <a
                      href="/admin-feriado"
                      style={{ cursor: "pointer", background: "grey" }}
                    >
                      Feriados
                    </a>
                    <a
                      href="/admin-suppliers"
                      style={{ cursor: "pointer", background: "grey" }}
                    >
                      Fornecedores
                    </a>
                    <a
                      href="/admin-defeitos"
                      style={{ cursor: "pointer", background: "grey" }}
                    >
                      Defeitos
                    </a>
                    <a
                      href="/admin-embalagens"
                      style={{ cursor: "pointer", background: "grey" }}
                    >
                      Embalagens
                    </a>
                    {privilegeAcess && (
                      <a
                        href="/admin-carga-dados"
                        style={{ cursor: "pointer", background: "grey" }}
                      >
                        Carga de Dados
                      </a>
                    )}
                  </div>
                )}
              </li>
            )}
          </ul>
        </nav>
        <div id="content">{children}</div>
      </div>
    </>
  );
};

export default HomeHeader;
