import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Admin from "./pages/admin/admin";
import ErrorsNce from "./pages/estratificacao-defeitos";
import Menu from "./ui/components/Menu/homeHeader";
import Nce from "./pages/nce/nce";
import ComparePage from "./pages/comparativo/comparativo";
import Analitico from "./pages/analitic/analitico";
import PQMFMensal from "./pages/mensal/mensal";
import RankingPage from "./pages/ranking/ranking";
import { useAuth } from "./context/auth";
import AlterarSenha from "./pages/alterar-senha/alterar-senha";
import Teste from "./ui/components/comparativo/SelectComparativo/SelectComparativo";
import AdminCarga from "./pages/admin/admin-carga-dados";

const RoutesConfig = () => {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      <Menu>
        <div style={{ marginTop: 50 }}>
          <Routes>
            <Route path="/home" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="/alterar-senha" element={<AlterarSenha />} />
          </Routes>
          <Routes>
            <Route path="/admin-users" element={<Admin />} />
          </Routes>
          <Routes>
            <Route path="/admin-product" element={<Admin />} />
          </Routes>
          <Routes>
            <Route path="/admin-feriado" element={<Admin />} />
          </Routes>
          <Routes>
            <Route path="/admin-suppliers" element={<Admin />} />
          </Routes>
          <Routes>
            <Route path="/admin-defeitos" element={<Admin />} />
          </Routes>
          <Routes>
            <Route path="/admin-embalagens" element={<Admin />} />
          </Routes>
          <Routes>
            <Route path="/admin-carga-dados" element={<AdminCarga />} />
          </Routes>
          <Routes>
            <Route path="/estratificacoes" element={<ErrorsNce />} />
          </Routes>
          <Routes>
            <Route path="/Nce" element={<Nce />} />
          </Routes>
          <Routes>
            <Route path="/comparativo" element={<ComparePage />} />
          </Routes>
          <Routes>
            <Route path="/analitico" element={<Analitico />} />
          </Routes>
          <Routes>
            <Route path="/mensal" element={<PQMFMensal />} />
          </Routes>
          <Routes>
            <Route path="/ranking" element={<RankingPage />} />
          </Routes>
        </div>
      </Menu>
    </BrowserRouter>
  );
};

export default RoutesConfig;
