import { createContext, ReactNode, useContext, useState } from "react"
import secureLocalStorage from "react-secure-storage"

type UserContextProps = {
    children: ReactNode
}
// melhorar logica de contexto + localstorage
// interface User {
    // idUsuario: number
    // nomeUsuario: string
    // tipo: string
    // idFabrica: any
    // perfilAcesso: string
// }

const login = secureLocalStorage.getItem('login')
const nomeUsuario = secureLocalStorage.getItem('nomeUsuario')
var profile: any = secureLocalStorage.getItem('profile') || ""
const idFabrica = secureLocalStorage.getItem('idFabrica')
const idUsuario = secureLocalStorage.getItem('idUsuario')
const tipo = secureLocalStorage.getItem('tipo')
const email = secureLocalStorage.getItem('email')

let perfis =  ['usuarioFornecedor','usuarioColaborador','adminUnidade','superAdmin']

if(!perfis.includes(profile)){
    profile = 'usuarioFornecedor'
}

const initialValue = {
    user: {
        username: login || "",
        user: nomeUsuario || "",
        profile: profile || "",
        idFabrica: idFabrica || "",
        idUsuario: idUsuario || "",
        tipo: tipo || "",
        email: email || "",
    },
    setUser: () => { }
}

export const AuthContext = createContext<any>(initialValue);

export const AuthProvider = ({ children }: UserContextProps) => {

    // const transform = JSON.parse(userLocalStorage)
    
    // const infoUser = {
    //     login: transform[0].login,
    //     nomeUsuario: transform[0].nomeUsuario,
    //     profile: transform[0].perfilAcesso,
    //     idFabrica: transform[0].idFabrica,
    //     idUsuario: transform[0].idUsuario,
    //     tipo: transform[0].tipo
    // }

    // const [user, setUser] = useState(!!userLocalStorage ? infoUser : initialValue.user);
    const [user, setUser] = useState(initialValue.user);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext)

