import "../../ui/components/admin/admin.css";
import React, { useState, useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { AiOutlineSync } from "react-icons/ai";
import Axios from "../../data/api";
import secureLocalStorage from "react-secure-storage";

interface Fornecedor {
  ID_FORNECEDOR_UNIDADE: number;
  FORNECEDOR_UNIDADE_DESCRICAO: string;
  FORNECEDOR_DESCRICAO: string;
  notaQA: number;
  notaCOMP: number;
  notaLOG: number;
}

interface Historico {
  ID_FECHAMENTO: string;
  ID_FORNECEDOR_UNIDADE: string;
  FORNECEDOR_UNIDADE_DESCRICAO: string;
  CM: number;
  LG: number;
  QA: number;
}

const AdminCarga: React.FC = () => {
  const [data, setData] = useState<Fornecedor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [historico, setHistorico] = useState<Historico[]>([]);
  const [activeTab, setActiveTab] = useState("1");
  const [idFechamento, setIdFechamento] = useState("");
  const [numCargaAtual, setNumCargaAtual] = useState("");
  const [searchQueryPontuacao, setSearchQueryPontuacao] = useState("");
  const [searchQueryHistorico, setSearchQueryHistorico] = useState("");
  const [filteredDataHistorico, setFilteredDataHistorico] = useState(historico);

  useEffect(() => {
    fetchFornecedores();
    fetchHistorico();
  }, []);

  const fetchFornecedores = async () => {
    try {
      setLoading(true);
      const response = await Axios.get("/fornecedor/listTableCarga");

      if (response?.data && response?.data?.length > 0) {
        setData(response.data);
        setError(null);
      } else {
        setError("Nenhum dado encontrado.");
      }
    } catch (err) {
      setError("Erro ao buscar os dados. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  const fetchHistorico = async () => {
    try {
      const response = await Axios.get(`/fornecedor/viewHistoricoCargaMensal`);
      if (response?.data && response?.data?.length > 0) {
        setHistorico(response.data || []);
        setError(null);
      } else {
        setError("Nenhum dado encontrado.");
      }
    } catch (err) {
      setError("Erro ao buscar o histórico. Tente novamente mais tarde.");
    }
  };

  const filteredDataPontuacao = data?.filter((item) => {
    return (
      item.FORNECEDOR_DESCRICAO.toLowerCase().includes(
        searchQueryPontuacao.toLowerCase()
      ) ||
      item.FORNECEDOR_UNIDADE_DESCRICAO.toLowerCase().includes(
        searchQueryPontuacao.toLowerCase()
      )
    );
  });

  useEffect(() => {
    const newFilteredData = historico?.filter((item) => {
      return (
        item.FORNECEDOR_UNIDADE_DESCRICAO.toLowerCase().includes(
          searchQueryHistorico.toLowerCase()
        ) || item.ID_FECHAMENTO.toString().includes(searchQueryHistorico)
      );
    });
    setFilteredDataHistorico(newFilteredData);
  }, [searchQueryHistorico, historico]);

  const handleNotaChange = (
    id: number,
    field: "notaQA" | "notaCOMP" | "notaLOG",
    value: number
  ) => {
    const updatedData = data.map((item) =>
      item.ID_FORNECEDOR_UNIDADE === id ? { ...item, [field]: value } : item
    );
    setData(updatedData);
  };

  const handleRowSelection = (id: number) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const handleAplicar = async () => {
    try {
      const selectedData = data?.filter((item) =>
        selectedRows.includes(item.ID_FORNECEDOR_UNIDADE)
      );

      const hasEmptyNotes = selectedData.some(
        (item) =>
          item.notaQA === undefined ||
          item.notaQA === null ||
          item.notaCOMP === undefined ||
          item.notaCOMP === null ||
          item.notaLOG === undefined ||
          item.notaLOG === null
      );

      if (hasEmptyNotes) {
        alert(
          "Existe notas selecionadas vazias, preencha para enviar o fechamento"
        );
        return;
      }
      if (
        numCargaAtual === undefined ||
        numCargaAtual === null ||
        numCargaAtual === ""
      ) {
        alert("Preencha o Id Fechamento para continuar");
        return;
      }
      if (!selectedData || selectedData.length === 0) {
        alert("Nenhum dado selecionado para envio.");
        return;
      }

      const payload = selectedData.map((item) => ({
        ID_FECHAMENTO: numCargaAtual,
        ID_FORNECEDOR_UNIDADE: item.ID_FORNECEDOR_UNIDADE,
        QA: item.notaQA,
        CM: item.notaCOMP,
        LG: item.notaLOG,
      }));

      await Axios.post("/fornecedor/insertCarga", payload);
      alert(
        "Os indicadores foram enviados com sucesso! Por favor, aguarde e cheque na aba 'Histórico Mensal' para verificar se subiram os indicadores. Após isso, você pode executar a sincronização."
      );

      window.location.reload();
    } catch (error) {
      console.error("Erro ao enviar os indicadores:", error);
      alert(`Erro ao enviar os indicadores. Acione o suporte: ${error}`);
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSync = async () => {
    try {
      let nomeUsuario = secureLocalStorage.getItem("nomeUsuario");
      let userEmail = secureLocalStorage.getItem("email");
      const response = await Axios.post(`/fornecedor/executeProcedure`, {
        idFechamento: idFechamento,
        userName: nomeUsuario,
        userEmail: userEmail,
      });
      if (response.status === 201) {
        alert(response.data.message);
      } else {
        throw error;
      }
    } catch (error) {
      alert(`Erro ao tentar sincronizar. Acione o suporte: ${error}`);
    }
  };

  return (
    <div>
      <h1>Administração de Carga Mensal de Dados</h1>

      {loading && <p>Carregando os dados...</p>}

      {error && !loading && <div className="alert alert-danger">{error}</div>}

      {!loading && !error && data.length > 0 && (
        <>
          <Nav tabs style={{ position: "relative" }}>
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => toggleTab("1")}
              >
                Pontuação
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => toggleTab("2")}
              >
                Histórico Mensal
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => toggleTab("3")}
              >
                Sincronizar tabelas
              </NavLink>
            </NavItem>

            {activeTab === "1" ? (
              <div
                style={{
                  border: "1px",
                  borderColor: "#77777792",
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <label>Digite o ID do Fechamento ( Ano + Mes ):</label>
                <input
                  type="text"
                  className="form-control"
                  id="idFechamento2"
                  placeholder="Digite o ID Fechamento"
                  value={numCargaAtual}
                  maxLength={6}
                  onChange={(e) => setNumCargaAtual(e.target.value)}
                  style={{ width: "auto", position: "relative", left: "90px" }} // Ajuste o tamanho do input conforme necessário
                />
              </div>
            ) : (
              ""
            )}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <>
                <div
                  className="card "
                  style={{ maxWidth: "auto", margin: "20px auto" }}
                >
                  <div className="card-body">
                    <h5 className="card-title"> Buscar Fornecedor </h5>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control card-text"
                        placeholder="Buscar o nome do fornecedor..."
                        style={{ width: "300px" }}
                        id="searchFornecedor"
                        value={searchQueryPontuacao}
                        onChange={(e) =>
                          setSearchQueryPontuacao(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <table className="table table-bordered table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th>Selecionar</th>
                      <th>Fornecedor</th>
                      <th>Unidade</th>
                      <th>IDFechamento</th>
                      <th>Nota QA</th>
                      <th>Nota COMP</th>
                      <th>Nota LOG</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDataPontuacao?.map((item) => (
                      <tr
                        key={item.ID_FORNECEDOR_UNIDADE}
                        className={
                          selectedRows.includes(item.ID_FORNECEDOR_UNIDADE)
                            ? ""
                            : "dimmed-row"
                        }
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(
                              item.ID_FORNECEDOR_UNIDADE
                            )}
                            onChange={() =>
                              handleRowSelection(item.ID_FORNECEDOR_UNIDADE)
                            }
                          />
                        </td>
                        <td>{item.FORNECEDOR_DESCRICAO}</td>
                        <td>{item.FORNECEDOR_UNIDADE_DESCRICAO}</td>
                        <th>{numCargaAtual}</th>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={item.notaQA}
                            onChange={(e) =>
                              handleNotaChange(
                                item.ID_FORNECEDOR_UNIDADE,
                                "notaQA",
                                parseFloat(e.target.value) || 0
                              )
                            }
                            disabled={
                              !selectedRows.includes(item.ID_FORNECEDOR_UNIDADE)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={item.notaCOMP}
                            onChange={(e) =>
                              handleNotaChange(
                                item.ID_FORNECEDOR_UNIDADE,
                                "notaCOMP",
                                parseFloat(e.target.value) || 0
                              )
                            }
                            disabled={
                              !selectedRows.includes(item.ID_FORNECEDOR_UNIDADE)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={item.notaLOG}
                            onChange={(e) =>
                              handleNotaChange(
                                item.ID_FORNECEDOR_UNIDADE,
                                "notaLOG",
                                parseFloat(e.target.value) || 0
                              )
                            }
                            disabled={
                              !selectedRows.includes(item.ID_FORNECEDOR_UNIDADE)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="sticky-footer">
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleAplicar}
                    disabled={selectedRows.length === 0}
                  >
                    Inserir notas{" "}
                    <AiOutlineCheck style={{ marginRight: "5px" }} />
                  </button>
                </div>
              </>
            </TabPane>

            <TabPane tabId="2">
              <div className="historico-section">
                <div
                  className="card "
                  style={{ maxWidth: "auto", margin: "20px auto" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">
                      Buscar fechamento ou fornecedor
                    </h5>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control card-text"
                        placeholder="Buscar fechamento ou fornecedor..."
                        style={{ width: "300px" }}
                        id="searchFechamento"
                        value={searchQueryHistorico}
                        onChange={(e) => {
                          setSearchQueryHistorico(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="historico-table">
                  <table className="table table-bordered table-striped mt-4">
                    <thead className="thead-dark">
                      <tr>
                        <th>Fechamento</th>
                        <th>Fornecedor Unidade</th>
                        <th>CM</th>
                        <th>LG</th>
                        <th>QA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDataHistorico?.map((item) => (
                        <tr
                          key={`${item.ID_FECHAMENTO}-${item.ID_FORNECEDOR_UNIDADE}`}
                        >
                          <td>{item.ID_FECHAMENTO}</td>
                          <td>{item.FORNECEDOR_UNIDADE_DESCRICAO}</td>
                          <td>{item.CM}</td>
                          <td>{item.LG}</td>
                          <td>{item.QA}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPane>

            <TabPane tabId="3">
              <div>
                <h2>Sincronizar Tabelas</h2>

                <div className="row">
                  <div className="col-6">
                    <label htmlFor="idFechamento" className="form-label">
                      Insira o ID do Fechamento :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="idFechamento"
                      placeholder="Digite o ano e o mês, ex : 202409"
                      value={idFechamento}
                      maxLength={6}
                      onChange={(e) => setIdFechamento(e.target.value)}
                    />
                  </div>

                  <div className="col-4 d-flex align-items-end">
                    <button
                      className="btn btn-success"
                      onClick={handleSync}
                      disabled={idFechamento.length !== 6}
                    >
                      Sincronizar
                      <AiOutlineSync style={{ marginLeft: "5px" }} />
                    </button>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </>
      )}
    </div>
  );
};

export default AdminCarga;
